/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import messages from '../../constants/messages';
import styles from './Summary.module.css';
import SummarySection from './SummarySection/index';
import {
  BulldozerSummary,
  SummarySectionCategory,
  TruckCategory,
} from '../../models';
import { SelectedAsset, TruckType } from '../model';
import SummaryHeader from '../components/SummaryHeader';
import LoaderSummary from './SummarySection/LoaderSummary';
import { DateRange } from 'react-day-picker';
import { useAssetContext } from '../../pages/service/assetContext';
import {
  getContactNearby,
  getEngineHours,
  getFuel,
  getIdleTime,
  getMaxPushes,
  getPushes,
  getSpeed,
} from '../services';
import { CheckIsLive, roundOff2 } from '../timeline/helper';
import LoaderSpinner from '../assets/LoaderSpinner';
import {
  currentDateNZ,
  formatAsNDigitNumber,
  getHoursInRange,
} from '../helper';
import useMediaQuery from '../../hooks/useMediaQuery';
import { bulldozerSummaryInitialData, maxHeightBreakPoint } from '../constants';
import { AuthContext } from '../../pages/service/auth';

type SummaryProps = {
  finalRange: DateRange | undefined;
  setFinalRange: Function;
  setxAxisValueSelect: Function;
  xAxisValueSelect: string | undefined;
};

const Summary: FunctionComponent<SummaryProps> = ({
  finalRange,
  setFinalRange,
  setxAxisValueSelect,
  xAxisValueSelect,
}: SummaryProps) => {
  const {
    assets,
    pushesLive,
    engineHoursLive,
    totalEngineHoursLive,
    contactTimeLive,
    nearbyTimeLive,
    isLoading,
    idleTimeLive,
    averageSpeedLive,
    selectedAssets,
  } = useAssetContext();

  const [isLive, setIsLive] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const abortControllers = useRef(new Map<string, AbortController>());

  const createAbortController = (key: string): AbortController => {
    const abortController = new AbortController();
    abortControllers.current.set(key, abortController);
    return abortController;
  };

  const abortPreviousRequests = () => {
    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current.clear();
  };

  useEffect(() => {
    if (CheckIsLive(finalRange)) {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
  }, [finalRange]);

  const { logOut } = useContext(AuthContext);

  const [summaryData, setsummaryData] = useState<BulldozerSummary>(
    bulldozerSummaryInitialData,
  );

  const fetchTotalMachineHours = async (
    assetName: string,
  ): Promise<number | undefined> => {
    try {
      const totalMachineHours = await getEngineHours(
        { from: currentDateNZ, to: undefined },
        assetName,
        createAbortController('getTotalMachineHours').signal,
        logOut,
      );

      if (totalMachineHours?.totalEngineHoursInMinutes) {
        return totalMachineHours.totalEngineHoursInMinutes / 60;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async (
    assetChanged = false,
    showLoader = true,
  ): Promise<void> => {
    console.log(`[Summary] Fetching data for all assets.`);

    let summaryTempData: BulldozerSummary = { ...bulldozerSummaryInitialData };

    try {
      if (showLoader) {
        setLoading(true);
      }

      abortPreviousRequests(); // Abort any previous requests

      // Fetch data for each selected asset
      await Promise.all(
        selectedAssets.map(async (selectedAsset: SelectedAsset) => {
          const [
            currentPushes,
            maximumPushes,
            averageSpeed,
            contactNearby,
            engineHours,
            idleTime,
            fuel,
          ] = await Promise.allSettled([
            getPushes(
              finalRange,
              selectedAsset.name,
              createAbortController('getPushes').signal,
              logOut,
            ),
            getMaxPushes(),
            getSpeed(
              finalRange,
              selectedAsset.name,
              createAbortController('getSpeed').signal,
              logOut,
            ),
            getContactNearby(
              finalRange,
              selectedAsset.name,
              createAbortController('getContactNearby').signal,
              logOut,
            ),
            getEngineHours(
              finalRange,
              selectedAsset.name,
              createAbortController('getEngineHours').signal,
              logOut,
            ),
            getIdleTime(
              finalRange,
              selectedAsset.name,
              createAbortController('getIdleTime').signal,
              logOut,
            ),
            getFuel(),
          ]);

          // Aggregate data into summaryTempData
          summaryTempData.currentPushes +=
            currentPushes.status === 'fulfilled' ? currentPushes.value ?? 0 : 0;
          summaryTempData.maximumPushes +=
            maximumPushes.status === 'fulfilled' ? maximumPushes.value ?? 0 : 0;
          summaryTempData.averageSpeed +=
            averageSpeed.status === 'fulfilled' ? averageSpeed.value ?? 0 : 0;

          if (contactNearby.status === 'fulfilled' && contactNearby.value) {
            summaryTempData.contactTime += contactNearby.value.contact ?? 0;
            summaryTempData.nearbyTime += contactNearby.value.nearby ?? 0;
          }

          summaryTempData.engineHours +=
            engineHours.status === 'fulfilled'
              ? engineHours.value?.calculatedEngineMinutes ?? 0
              : 0;
          summaryTempData.idleTime +=
            idleTime.status === 'fulfilled' ? idleTime.value ?? 0 : 0;
          console.log(getHoursInRange(finalRange), finalRange, 'SUMMARY');
          summaryTempData.inactiveTime +=
            engineHours.status === 'fulfilled'
              ? getHoursInRange(finalRange) * 60 -
                roundOff2(engineHours.value?.calculatedEngineMinutes ?? 0)
              : 0;
          summaryTempData.transmissionTime +=
            engineHours.status === 'fulfilled'
              ? (engineHours.value?.calculatedEngineMinutes ?? 0) -
                (idleTime.status === 'fulfilled' ? idleTime.value ?? 0 : 0)
              : 0;
          summaryTempData.fuel +=
            fuel.status === 'fulfilled' ? fuel.value ?? 0 : 0;

          if (assetChanged) {
            const totalHours: number | undefined = await fetchTotalMachineHours(
              selectedAsset.name,
            );
            summaryTempData.totalEngineHours += totalHours ?? 0;
          } else {
            summaryTempData.totalEngineHours =
              summaryData.totalEngineHours ?? 0;
          }
        }),
      );
      summaryTempData.averageSpeed =
        summaryTempData.averageSpeed / selectedAssets.length;
      setsummaryData(summaryTempData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      abortPreviousRequests(); // Abort requests on component unmount
    };
  }, [finalRange]);

  useEffect(() => {
    fetchData(true);
    return () => {
      abortPreviousRequests(); // Abort requests on component unmount
    };
  }, [selectedAssets]);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       fetchData(false, false);
  //     },
  //     Number(process.env.REACT_APP_API_REFRESH_INTERVAL_IN_MINUTES ?? 5) *
  //       60 *
  //       1000,
  //   );
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [selectedAsset, finalRange]);
  useEffect(() => {
    // Only update the summary data with the socket response if date range is not selected and a single asset is selected as we cannot handle socket data for multidozer
    if (isLive && selectedAssets.length === 1) {
      if (
        contactTimeLive &&
        selectedAssets[0].name === contactTimeLive.truckName
      ) {
        console.log(`updating contactTime summary`, { contactTimeLive });
        setsummaryData((previousSummary) => ({
          ...previousSummary,
          contactTime: contactTimeLive.value,
        }));
      }
      if (
        nearbyTimeLive &&
        selectedAssets[0].name === nearbyTimeLive.truckName
      ) {
        console.log(`updating nearbyTime summary`, { nearbyTimeLive });
        setsummaryData((previousSummary) => ({
          ...previousSummary,
          nearbyTime: nearbyTimeLive.value,
        }));
      }
      if (
        averageSpeedLive &&
        selectedAssets[0].name === averageSpeedLive.truckName
      ) {
        console.log('updating average speed summary');
        setsummaryData((previousSummary) => ({
          ...previousSummary,
          averageSpeed: +formatAsNDigitNumber(
            Number(averageSpeedLive.value ?? 0),
            2,
          ),
        }));
      }
      if (
        engineHoursLive &&
        totalEngineHoursLive &&
        selectedAssets[0].name === totalEngineHoursLive.truckName
      ) {
        console.log('updating enginehour summary');

        setsummaryData((previousSummary) => ({
          ...previousSummary,
          inactiveTime:
            getHoursInRange(finalRange) * 60 - roundOff2(engineHoursLive.value),
          transmissionTime: engineHoursLive.value - summaryData.idleTime,
          engineHours: engineHoursLive.value,
          totalEngineHours: totalEngineHoursLive.value,
        }));
      }
      if (pushesLive && selectedAssets[0].name === pushesLive.truckName) {
        console.log('updating pushes summary');
        setsummaryData((previousSummary) => ({
          ...previousSummary,
          currentPushes: pushesLive.value,
        }));
      }
    }
  }, [
    contactTimeLive,
    nearbyTimeLive,
    averageSpeedLive,
    engineHoursLive,
    totalEngineHoursLive,
    pushesLive,
  ]);

  useEffect(() => {
    if (isLive && selectedAssets.length === 1) {
      if (idleTimeLive && selectedAssets[0].name === idleTimeLive.truckName) {
        console.log('updating idletime summary');
        setsummaryData({
          ...summaryData,
          transmissionTime: summaryData.engineHours - idleTimeLive.value,
          idleTime: idleTimeLive.value,
        });
      }
    }
  }, [idleTimeLive]);

  const { height } = useMediaQuery();
  return (
    <>
      <SummaryHeader
        finalRange={finalRange}
        setFinalRange={setFinalRange}
        setxAxisValueSelect={setxAxisValueSelect}
        xAxisValueSelect={xAxisValueSelect}
      />
      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          overflow: 'auto',
          boxShadow: '0px 3px 6px #00000029',
          border: '1px solid #EBEBEB',
          borderRadius: '4px',
        }}
      >
        {selectedAssets.findIndex(
          (selectedAsset: SelectedAsset) =>
            selectedAsset.type === TruckType.DMU,
        ) !== -1 ? (
          <>
            <div className={styles.summaryContainer}>
              <div className={styles.summaryTitle}>
                {messages.DMU.DASHBOARD.SUMMARY}
              </div>
              <div className={styles.summaryCardsContainer}>
                <SummarySection
                  data={summaryData}
                  sectionCategory={SummarySectionCategory.PRODUCTIVITY}
                  truckCategory={TruckCategory.DMU}
                  range={finalRange}
                />
              </div>
            </div>
            <LoaderSummary />
          </>
        ) : (
          <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>
              {messages.DMU.DASHBOARD.SUMMARY}
            </div>
            <div className={styles.summaryCardsContainer}>
              {loading || isLoading ? (
                <LoaderSpinner
                  containerWidth={'100%'}
                  containerHeight={height <= maxHeightBreakPoint ? 185 : 205}
                  height={50}
                  width={50}
                />
              ) : assets.length === 0 ? (
                <div style={{ height: '240px' }}>No data</div>
              ) : (
                <>
                  <SummarySection
                    sectionCategory={SummarySectionCategory.PRODUCTIVITY}
                    truckCategory={TruckCategory.BULLDOZER}
                    range={finalRange}
                    data={summaryData}
                  />
                  <div className={styles.verticalLine}></div>
                  <SummarySection
                    sectionCategory={SummarySectionCategory.UTILIZATION}
                    truckCategory={TruckCategory.BULLDOZER}
                    range={finalRange}
                    data={summaryData}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Summary;
